<template>
  <Row class="stake-detail relative bg-dark-blue">
    <Col :lg="12" :span="24" class="pt-6 pb-6 ps-6 pe-6 bg-dark-blue" v-if="content.status !== 'CANCELED'">
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.approximate_active_reward") }}
      </p>
      <p class="ltr color-green font-Medium-18">
        ≈{{ content.details[0].activeApy }}%
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.rewards_earned") }}
      </p>
      <p class="ltr text-end color-green font-Medium-16">
        {{
          truncateToDecimals(
            content.details[0].currentReward,
            content.coinDto.rewardScale
          )
        }}
        {{ content.coinDto.coinUnit }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.participation_rate") }}
      </p>
      <p class="ltr color-white font-Medium-16">
        {{
          truncateToDecimals(
            content.details[0].stakedAmount,
            content.coinDto.rewardScale
          )
        }}
        {{ content.coinDto.coinUnit }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3" v-if="
      content.details[0].cancelable &&
      content.status !== 'CANCELED' &&
      content.status !== 'TERMINATED'
    ">
      <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
        {{ $t("staking.cancel_fee") }}
        <span class="color-orange font-Medium-12 ps-2 cancel-plan" @click="cancelStake">{{ $t("staking.cancel")
          }}</span>
      </p>
      <p class="ltr color-white font-Medium-16">
        {{
          content.details[0].cancelFee > 0
            ? `${content.details[0].cancelFee}%`
            : $t("staking.free")
        }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3" v-if="content.details[0].isRenewal">
      <p class="color-gray text-start font-Regular-14">
        {{ $t("staking.renewal_fee") }}
      </p>
      <p class="color-white font-Regular-16 ltr">
        {{
          content.details[0].renewalFee === 0
            ? $t("staking.free")
            : `${content.details[0].renewalFee}%`
        }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.remain_duration") }}
      </p>
      <p class="color-white text-end font-Medium-16" :class="isLocaleLTR ? 'rtl' : 'ltr'">
        {{ content.details[0].duration }} /
        {{ content.details[0].remainingRegistrationTime }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.start_date") }}
      </p>
      <p class="color-white font-Medium-16">
        {{ dateFormat(content.details[0].subscriptionTime, false) }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
        {{ $t("staking.release_duration") }}
      </p>
      <p class="color-white font-Medium-16">
        {{
          content.details[0].releaseDuration === 0
            ? $t("staking.instant")
            : `${content.details[0].releaseDuration} ${$t("staking.day")}`
        }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3"
      v-if="content.details[0].cancelable && content.status !== 'TERMINATED'">
      <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
        {{ $t("staking.cancel_release_duration") }}
      </p>
      <p class="color-white font-Medium-16">
        {{
          content.details[0].cancelReleaseDuration === 0
            ? $t("staking.instant")
            : `${content.details[0].cancelReleaseDuration} ${$t(
              "staking.day"
            )}`
        }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mt-4" v-if="
      content.details[0].isRenewal &&
      content.status !== 'CANCELED' &&
      content.status !== 'TERMINATED'
    ">
      <p class="color-white text-start font-Medium-16">
        {{ $t("staking.auto_renewal") }}
      </p>
      <i-switch :value="content.details[0].userApprovedRenewal" :before-change="handleAutoRenewalChange" />
    </div>
    </Col>
    <Col :lg="12" :span="24" class="pt-6 pb-6 ps-6 pe-6 bg-dark-blue" v-else>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.cancel_reward_earned") }}
      </p>
      <p class="ltr color-white font-Medium-16">
        {{ content.details[0].currentReward }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.participation_rate") }}
      </p>
      <p class="ltr color-white font-Medium-16">
        {{ content.details[0].stakedAmount }} {{ content.coinDto.coinUnit }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3" v-if="content.details[0].cancelable">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.cancel_fee") }}
      </p>
      <p class="ltr color-white font-Medium-16">
        {{
          content.details[0].cancelFee > 0
            ? `${content.details[0].cancelFee}%`
            : $t("staking.free")
        }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.start_date") }}
      </p>
      <p class="color-white font-Medium-16">
        {{ dateFormat(content.details[0].subscriptionTime, false) }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.cancel_date") }}
      </p>
      <p class="color-white font-Medium-16">
        <!-- {{ dateFormat(content.details[0].refuseTime, false) }} -->
        {{ dateFormat(content.details[0].cancelTime, false) }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3" v-if="content.details[0].renewalEndDate">
      <p class="color-gray text-start font-Regular-14">
        {{ $t("staking.renewal_end_date") }}
      </p>
      <p class="color-white font-Regular-16 ltr">
        {{ dateFormat(content.details[0].renewalEndDate, false) }}
      </p>
    </div>
    <div class="d-flex justify-between align-center mb-3">
      <p class="color-gray text-start font-Medium-14">
        {{ $t("staking.cancel_release_duration") }}
      </p>
      <p class="color-white font-Medium-16">
        {{
          content.details[0].cancelReleaseDuration === 0
            ? $t("staking.instant")
            : `${content.details[0].cancelReleaseDuration} ${$t("staking.day")}`
        }}
        <!-- {{
            content.details[0].assetReleaseTime
              ? dateFormat(content.details[0].assetReleaseTime, false)
              : "-"
          }} -->
      </p>
    </div>
    </Col>
    <!--    left side   -->
    <Col :lg="12" :span="24" class="relative bg-dim-gray">
    <div class="left-side">
      <CustomTab :navList="navList" :selectedTab="selectedTab" @updateSelectTab="updateSelectTab" />
      <Table v-if="selectedTab === 0" :columns="stakeHistoryColumn" :data="content.details[0].rewards"
        :disabled-hover="true" :loading="loading" :no-data-text="loading ? '' : $t('common.nodata')" class="mt-4"
        height="280">
        <template slot="loading">
          <Loader />
        </template>
      </Table>
      <Table v-if="selectedTab === 1" :columns="renewalHistoryColumn" :data="renewalData" :disabled-hover="true"
        :loading="loading" :no-data-text="loading ? '' : $t('common.nodata')" class="mt-4" height="280">
        <template slot="loading">
          <Loader />
        </template>
      </Table>

    </div>
    </Col>
  </Row>
</template>
<script>
import { mapActions } from "vuex";
import CustomTab from "../CustomTab";

export default {
  components: {
    CustomTab,
  },
  props: ["content"],
  data() {
    return {
      selectedTab: 0,
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      loading: false,
      renewalData: [],
      stakeHistoryColumn: [
        {
          title: this.$t("staking.date"),
          align: "right",
          minWidth: 180,
          render: (h, params) => {
            const date = params.row.end
              ? `${this.$options.filters.dateFormat(
                params.row.start,
                false
              )} ${this.$t('staking.to')} ${this.$options.filters.dateFormat(
                params.row.end,
                false
              )}`
              : this.$options.filters.dateFormat(params.row.start, false);
            return h("div", date);
          },
        },
        {
          title: this.$t("staking.reward_percent"),
          align: "right",
          minWidth: 120,
          key: "activeAPY",
          render: (h, params) => {
            return h(
              "div",
              { style: { direction: "ltr" } },
              `${params.row.activeAPY}%`
            );
          },
        },
        {
          title: this.$t("staking.earned_money"),
          align: "right",
          minWidth: 160,
          key: "rewardAmount",
          render: (h, params) => {
            return h(
              "div",
              { style: { direction: "ltr" } },
              `${this.truncateToDecimals(
                params.row.rewardAmount,
                this.content.coinDto.rewardScale
              )} ${this.content.coinDto.coinUnit}`
            );
          },
        },
      ],
      renewalHistoryColumn: [
        {
          title: this.$t("staking.renewal_date"),
          align: "right",
          minWidth: 180,
          render: (h, params) => {
            return h(
              "div",
              this.$options.filters.dateFormat(
                params.row.startPeriodTime,
                false
              )
            );
          },
        },
        {
          title: this.$t("staking.renewal_status"),
          align: "right",
          minWidth: 110,
          key: "rewardPeriodStatus",
          render: (h, params) => {
            return h(
              "div",
              params.row.rewardPeriodStatus === "IN_PROGRESS"
                ? this.$t("staking.status_active")
                : params.row.rewardPeriodStatus === "TERMINATED"
                  ? this.$t("staking.status_terminated")
                  : this.$t("staking.status_cancel")
            );
          },
        },
      ],
      navList: [
        { id: 0, label: this.$t("staking.reward_table") },
        { id: 1, label: this.$t("staking.renewal_table") },
      ],
    };
  },
  methods: {
    ...mapActions({
      getRenewalHistory: "staking/GET_RENEWAL_HISTORY",
    }),
    dateFormat: function (tick, option) {
      return this.$options.filters.dateFormat(tick, option);
    },
    handleAutoRenewalChange() {
      return new Promise((resolve) => {
        this.$emit("renewalModal", this.content.details[0].id, this.content.details[0].userApprovedRenewal);
      });
    },
    cancelStake() {
      this.$emit("cancelModal", this.content.details[0].id);
    },
    clearForm() {
      this.selectedTab = 0;
    },
    updateSelectTab(id) {
      this.selectedTab = id;
      if (this.selectedTab === 1) {
        this.getRenewalHistory({
          subscriptionId: this.content.details[0].id,
        })
          .then((res) => {
            this.renewalData = res.data.data;
          })
          .catch((err) => this.$Message.error(err));
      }
    },
    truncateToDecimals(num, dec = 2) {
      const calcDec = Math.pow(10, dec);
      return Math.trunc(num * calcDec) / calcDec;
    },
  },
};
</script>
<style lang="scss" scoped>
.max-w-160-responsive {
  @media (max-width: 348px) {
    max-width: 160px;
  }
}

.stake-detail {
  .cancel-plan {
    &:hover {
      cursor: pointer;
    }
  }

  .left-side {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
      height: 1px;
    }
  }
}
</style>
