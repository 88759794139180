<template>
  <div v-if="loading" class="h-273 d-flex justify-center">
    <Loader />
  </div>

  <Row class="stake-form relative bg-dark-blue" v-else>
    <Col :lg="12" :span="24" class="pt-6 pb-6 ps-6 pe-6 bg-dark-blue" v-if="!Object.keys(getMyStakeDetail).length">
    <template v-if="collapseState">
      <div class="mb-3 color-gray text-start font-Regular-12 line-height-24 ps-4 pe-4"
        style="max-height: 430px;overflow-y: auto;word-break: break-all" v-html="content.disclaimer">
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.yearly_active_apr") }}
        </p>
        <p class="ltr color-green font-Bold-18">
          <span v-if="content.apyType === 0">≈</span>{{ content.activeAPY }}%
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3" v-if="content.apyType === 0">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.min_max_apr") }}
        </p>
        <p class="color-white font-Medium-16">
          <span>{{ content.minApy }}%</span>
          {{ $t('staking.to') }}
          <span :dir="isLocaleLTR ? 'ltr' : 'rtl'">{{ content.maxApy }}%</span>
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.remained_plan") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{ numberWithCommas(content.remainCapacity) }}
          {{ coin.coinUnit }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.max_capacity") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{ numberWithCommas(content.maxCapacity) }}
          {{ coin.coinUnit }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.registration_time") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{ dateFormat(content.endDate, false) }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.min_max_input") }}
        </p>
        <p class="color-white text-end font-Medium-16">
          <span class="d-inline-block ltr">{{ content.minStakePerUser }} {{ coin.coinUnit }}</span>
          {{ $t("staking.to") }}
          <span class="d-inline-block ltr">{{ content.maxStakePerUser }} {{ coin.coinUnit }}</span>
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.registration_fee") }}
        </p>
        <p class="color-white font-Regular-16 ltr">
          {{
            content.enterFee === 0 ? $t("staking.free") : `${content.enterFee}%`
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.cancelable") }}
        </p>
        <p class="color-white font-Regular-16">
          {{ content.cancelable ? $t("staking.has") : $t("staking.not_has") }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3" v-if="content.cancelable">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.cancel_fee") }}
        </p>
        <p class="color-white font-Regular-16 ltr">
          {{
            content.cancelFee === 0
              ? $t("staking.free")
              : `${content.cancelFee}%`
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3" v-if="content.renewalEndDate">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.renewal_end_date") }}
        </p>
        <p class="color-white font-Regular-16 ltr">
          {{ dateFormat(content.renewalEndDate, false) }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-6" v-if="content.isRenewal">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.renewal_fee") }}
        </p>
        <p class="color-white font-Regular-16 ltr">
          {{
            content.renewalFee === 0
              ? $t("staking.free")
              : `${content.renewalFee}%`
          }}
        </p>
      </div>
      <!-- <div class="d-flex justify-between align-center mb-3">
          <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
            {{ $t("staking.release_duration") }}
          </p>
          <p class="color-white font-Medium-16">
            {{
              content.releaseDuration === 0
                ? $t("staking.instant")
                : `${content.releaseDuration} ${$t("staking.day")}`
            }}
          </p>
        </div>
        <div
          class="d-flex justify-between align-center mb-3"
          v-if="content.cancelable"
        >
          <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
            {{ $t("staking.cancel_release_duration") }}
          </p>
          <p class="color-white font-Medium-16">
            {{
              content.cancelReleaseDuration === 0
                ? $t("staking.instant")
                : `${content.cancelReleaseDuration} ${$t(
                    "staking.day"
                  )}`
            }}
          </p>
        </div> -->
    </template>

    </Col>
    <template v-else>
      <Col :lg="12" :span="24" class="pt-6 pb-6 ps-6 pe-6 bg-dark-blue" v-if="getMyStakeDetail.status !== 'CANCELED'">
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.approximate_active_reward") }}
        </p>
        <p class="ltr color-green font-Medium-18">
          ≈{{ getMyStakeDetail.activeApy }}%
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.rewards_earned") }}
        </p>
        <p class="ltr text-end color-green font-Medium-16">
          {{
            truncateToDecimals(
              getMyStakeDetail.currentReward,
              getMyStakeDetail.rewardScale
            )
          }}
          {{ coin.coinUnit }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.participation_rate") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{
            truncateToDecimals(
              getMyStakeDetail.stakedAmount,
              getMyStakeDetail.rewardScale
            )
          }}
          {{ coin.coinUnit }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3" v-if="
        getMyStakeDetail.cancelable &&
        getMyStakeDetail.status !== 'CANCELED' &&
        getMyStakeDetail.status !== 'TERMINATED'
      ">
        <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
          {{ $t("staking.cancel_fee") }}
          <span class="color-orange font-Medium-12 ps-2 cancel-plan" @click="cancelStake">{{ $t("staking.cancel")
            }}</span>
        </p>
        <p class="ltr color-white font-Medium-16">
          {{
            getMyStakeDetail.cancelFee > 0
              ? `${getMyStakeDetail.cancelFee}%`
              : $t("staking.free")
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3" v-if="getMyStakeDetail.isRenewal">
        <p class="color-gray text-start font-Regular-14">
          {{ $t("staking.renewal_fee") }}
        </p>
        <p class="color-white font-Regular-16 ltr">
          {{
            getMyStakeDetail.renewalFee === 0
              ? $t("staking.free")
              : `${getMyStakeDetail.renewalFee}%`
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.remain_duration") }}
        </p>
        <p class="color-white text-end font-Medium-16" :class="isLocaleLTR ? 'rtl' : 'ltr'">
          {{ getMyStakeDetail.duration }} /
          {{ getMyStakeDetail.remainingRegistrationTime }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.start_date") }}
        </p>
        <p class="color-white font-Medium-16">
          {{ dateFormat(getMyStakeDetail.subscriptionTime, false) }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
          {{ $t("staking.release_duration") }}
        </p>
        <p class="color-white font-Medium-16">
          {{
            getMyStakeDetail.releaseDuration === 0
              ? $t("staking.instant")
              : `${getMyStakeDetail.releaseDuration} ${$t("staking.day")}`
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3"
        v-if="getMyStakeDetail.cancelable && getMyStakeDetail.status !== 'TERMINATED'">
        <p class="color-gray text-start font-Medium-14 max-w-160-responsive">
          {{ $t("staking.cancel_release_duration") }}
        </p>
        <p class="color-white font-Medium-16">
          {{
            getMyStakeDetail.cancelReleaseDuration === 0
              ? $t("staking.instant")
              : `${getMyStakeDetail.cancelReleaseDuration} ${$t(
                "staking.day"
              )}`
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mt-4" v-if="
        getMyStakeDetail.isRenewal &&
        getMyStakeDetail.status !== 'CANCELED' &&
        getMyStakeDetail.status !== 'TERMINATED'
      ">
        <p class="color-white text-start font-Medium-16">
          {{ $t("staking.auto_renewal") }}
        </p>
        <i-switch ref="switchRef" :value="getMyStakeDetail.userApprovedRenewal"
          :before-change="handleAutoRenewalChange" />
      </div>
      </Col>
      <Col :lg="12" :span="24" class="pt-6 pb-6 ps-6 pe-6 bg-dark-blue" style="min-height: 380px" v-else>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.cancel_reward_earned") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{ getMyStakeDetail.currentReward }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.participation_rate") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{ getMyStakeDetail.stakedAmount }} {{ coin.coinUnit }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3" v-if="getMyStakeDetail.cancelable">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.cancel_fee") }}
        </p>
        <p class="ltr color-white font-Medium-16">
          {{
            getMyStakeDetail.cancelFee > 0
              ? `${getMyStakeDetail.cancelFee}%`
              : $t("staking.free")
          }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.start_date") }}
        </p>
        <p class="color-white font-Medium-16">
          {{ dateFormat(getMyStakeDetail.subscriptionTime, false) }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.cancel_date") }}
        </p>
        <p class="color-white font-Medium-16">
          <!-- {{ dateFormat(content.refuseTime, false) }} -->
          {{ dateFormat(getMyStakeDetail.cancelTime, false) }}
        </p>
      </div>
      <div class="d-flex justify-between align-center mb-3">
        <p class="color-gray text-start font-Medium-14">
          {{ $t("staking.cancel_release_duration") }}
        </p>
        <p class="color-white font-Medium-16">
          {{
            getMyStakeDetail.cancelReleaseDuration === 0
              ? $t("staking.instant")
              : `${getMyStakeDetail.cancelReleaseDuration} ${$t("staking.day")}`
          }}
          <!-- {{
              content.assetReleaseTime
                ? dateFormat(content.assetReleaseTime, false)
                : "-"
            }} -->
        </p>
      </div>
      </Col>
    </template>
    <!--left side-->
    <Col :lg="12" :span="24" class="ps-2 pe-2 relative bg-dim-gray">
    <CustomTab :navList="navList" :selectedTab="selectedTab" @updateSelectTab="updateSelectTab" class="text-start" />
    <template class="" v-if="selectedTab === 0">
      <div class="d-flex flex-direction-column justify-center align-center h-250" v-if="
        content.isRegisterEnded ||
        content.isRegisterNotStarted ||
        content.remainCapacity === 0
      ">
        <BaseIcon name="NoData" />
        <p class="text-center font-Regular-12 color-gray">
          {{ $t("staking.noData_table") }}
        </p>
      </div>
      <div class="left-side mt-4" v-else>
        <p class="color-gray text-start font-Regular-16">
          {{ $t("staking.reward") }}
          <span class="color-gray text-center font-Regular-12">({{ coin.coinUnit }})</span>
        </p>
        <div class="mt-2 responsive-reward-container">
          <Row>
            <Col span="12">
            <p class="color-dark-gray text-start font-Regular-14">
              {{ $t("staking.daily") }}
            </p>
            <p class="color-white text-start font-Regular-16">
              {{
                truncateToDecimals((Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                  365, coin.rewardScale) ? truncateToDecimals(
                    (Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                    365,
                    coin.rewardScale
                  ).toLocaleString() : 0
              }}
            </p>
            </Col>
            <Col span="12">
            <p class="color-dark-gray text-start font-Regular-14">
              {{ $t("staking.weekly") }}
            </p>
            <p class="color-white text-start font-Regular-16">
              {{
                truncateToDecimals(
                  ((Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                    52) *
                  7,
                  coin.rewardScale
                ) ? truncateToDecimals(
                  (Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                  52,
                  coin.rewardScale
                ).toLocaleString() : 0
              }}
            </p>
            </Col>
          </Row>
          <Row>
            <Col span="12">
            <p class="color-dark-gray text-start font-Regular-14">
              {{ $t("staking.monthly") }}
            </p>
            <p class="color-white text-start font-Regular-16">
              {{
                truncateToDecimals(
                  ((Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                    12) *
                  30,
                  coin.rewardScale
                ) ? truncateToDecimals(
                  (Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                  12,
                  coin.rewardScale
                ).toLocaleString() : 0
              }}
            </p>
            </Col>
            <Col span="12">
            <p class="color-dark-gray text-start font-Regular-14">
              {{ $t("staking.yearly") }}
            </p>
            <p class="color-white text-start font-Regular-16">
              {{
                truncateToDecimals(
                  Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100),
                  coin.rewardScale
                ) ? truncateToDecimals(
                  (Number(parseFloat(removeThousand(stakeAmountInput))) * (content.activeAPY / 100)) /
                  1,
                  coin.rewardScale
                ).toLocaleString() : 0
              }}
            </p>
            </Col>
          </Row>
        </div>
        <div class="d-flex justify-between align-center mt-4" v-if="isLogin">
          <p class="color-dark-gray text-start font-Regular-14">
            {{ $t("staking.balance") }}
            <span class="color-white text-start font-Regular-14 ps-2">
              {{ wallet.balance | numberWithCommas(wallet.scale) }}
              {{ coin.coinUnit }}
            </span>
          </p>
          <a class="text-center font-Medium-12 color-orange increase-balance"
            :href="baseUrl + `/panel/operations/transfer?from=${coin.coinUnit}`">{{ $t("staking.increase_balance")
            }}</a>
        </div>
        <div :class="[
          Number(parseFloat(removeThousand(stakeAmountInput))) > Number(wallet.balance) &&
          isLogin &&
          'insufficient-input',
          'relative',
        ]">
          <!-- <div
              class="absolute font-Regular-14 color-white z-index-100 color-white slider-input-left-hint"
            >
              {{ coin.coinUnit }}
            </div> -->
          <!-- <div
              class="absolute z-index-100 color-white text-start font-Regular-12 slider-input-right-hint"
            >
              {{ $t("staking.stake_amount") }}
            </div> -->
          <div v-if="Number(parseFloat(removeThousand(stakeAmountInput))) > Number(wallet.balance) && isLogin"
            class="absolute z-index-100 text-start font-Regular-12 color-red insufficient-hint">
            {{ $t("staking.insufficient_balance") }}
          </div>
          <div v-else-if="
            Number(parseFloat(removeThousand(stakeAmountInput))) < content.minStakePerUser ||
            Number(parseFloat(removeThousand(stakeAmountInput))) > content.maxStakePerUser
          " class="absolute z-index-100 text-start font-Regular-12 color-red insufficient-hint">
            {{
              Number(parseFloat(removeThousand(stakeAmountInput))) < content.minStakePerUser ?
                $t("staking.insufficient_min_amount") : $t("staking.insufficient_max_amount") }} </div>
              <Input @on-keyup="handleStakeAmount" v-model="stakeAmountInput" :class="[
                'custom-stake-input',
                (Number(parseFloat(removeThousand(stakeAmountInput))) < content.minStakePerUser ||
                  Number(parseFloat(removeThousand(stakeAmountInput))) > content.maxStakePerUser) &&
                'invalid-stake-amount',
              ]">
              <span slot="append">{{ coin.coinUnit }}</span>
              <span slot="append" style="color:#F1AC1C;cursor: pointer" class="font-Medium-10 ms-4"
                @click="setAllBalance">{{ $t('staking.all_balance') }}</span>
              <span slot="prepend">{{ $t("staking.stake_amount") }}</span>
              </Input>

              <!--            <Slider-->
              <!--                class="ltr"-->
              <!--                v-model="stakeAmount"-->
              <!--                :step="1 / Math.pow(10, countDecimals(content.minStakePerUser))"-->
              <!--                :min="content.minStakePerUser"-->
              <!--                :max="content.maxStakePerUser"-->
              <!--                @on-change="logSlider"-->
              <!--            ></Slider>-->
              <!--            <div class="d-flex justify-between ltr">-->
              <!--              <p class="ltr color-dark-gray font-Regular-10">-->
              <!--                {{ content.minStakePerUser }} {{ coin.coinUnit }}-->
              <!--              </p>-->
              <!--              <p class="ltr color-dark-gray font-Regular-10">-->
              <!--                {{ content.maxStakePerUser }} {{ coin.coinUnit }}-->
              <!--              </p>-->
              <!--            </div>-->
          </div>
          <div class="d-flex justify-between align-center mt-10" v-if="content.isRenewal">
            <p class="color-white text-start font-Regular-16">
              {{ $t("staking.auto_renewal") }}
            </p>
            <i-switch v-model="autoRenewal" />
          </div>
          <div class="d-flex align-start mt-4">
            <Checkbox v-model="termsConditions" class="mt-2" />
            <Collapse class="terms-conditions-detail" @on-change="collapseTrigger" accordion>
              <Panel hide-arrow class="collapse-panel mb-0">
                <div class="d-flex flex-row-reverse justify-end align-center">
                  <div class="d-flex align-center">
                    <p class="d-inline-block color-gray font-Medium-14 text-start">
                      <span class="color-orange text-start font-Regular-14" :class="isLocaleLTR && 'ps-2'">{{
                        $t("staking.terms_1") }}
                        {{ content.description }}
                      </span>
                      {{ $t("staking.terms_2") }}
                      <!--                      <Icon-->
                      <!--                          color="#A2AEBF"-->
                      <!--                          size="14"-->
                      <!--                          :type="-->
                      <!--                        collapseState === '0'-->
                      <!--                          ? 'ios-arrow-up'-->
                      <!--                          : 'ios-arrow-down'-->
                      <!--                      "-->
                      <!--                      />-->
                    </p>
                  </div>
                </div>
                <!--                <template slot="content">-->
                <!--                  <div-->
                <!--                      class="mb-3 color-gray text-start font-Regular-12 line-height-24"-->
                <!--                      v-html="content.disclaimer"-->
                <!--                  ></div>-->
                <!--                </template>-->
              </Panel>
            </Collapse>
          </div>
        </div>
        <div class="pb-4 pt-4 d-flex align-end sticky w-100 bg-dim-gray button-container" :lg="{ span: 12, pull: 12 }"
          :span="24" v-if="
            !content.isRegisterEnded &&
            !content.isRegisterNotStarted &&
            content.remainCapacity !== 0
          ">
          <Button class="confirm-btn" @click="stakeButton" :loading="loadingBtn" :disabled="(Number(parseFloat(removeThousand(stakeAmountInput))) > Number(wallet.balance) && isLogin) ||
            (isLogin && !termsConditions) ||
            (Number(parseFloat(removeThousand(stakeAmountInput))) < Number(content.minStakePerUser) &&
              isLogin) ||
            (Number(parseFloat(removeThousand(stakeAmountInput))) > Number(content.maxStakePerUser) &&
              isLogin)
            ">
            <span v-if="isLogin">{{ $t("staking.stake") }}</span>
            <span v-else>{{ $t("staking.login") }}</span>
          </Button>
          <Button class="cancel-btn" @click="closeModal">
            <span>{{ $t("staking.cancel") }}</span>
          </Button>
        </div>
    </template>
    <template v-if="selectedTab === 1">
      <Table :disabled-hover="true" :no-data-text="isLogin ? '' : $t('common.nodata')" :data="getMyStakeData"
        :columns="myStakesColumn" @on-row-click="rowClickHandler" class="mt-4" height="280"
        :row-class-name="rowClassName" />
      <Button class="confirm-btn" @click="stakeButton" :loading="loadingBtn" v-if="!isLogin">
        <span>{{ $t("staking.login") }}</span>
      </Button>
    </template>
    </Col>
  </Row>
</template>
<script>
import BaseIcon from "@/components/UI/BaseIcon";
import Loader from "@/components/UI/LoadingComp";
import CustomTab from "@/components/Staking/CustomTab/index.vue";
import { mapActions, mapGetters } from "vuex";
import { handleInput, removeThousand } from "@/utiles/numericValidations";

export default {
  components: {
    CustomTab,
    BaseIcon,
    Loader,
  },
  props: ["content", "coin", "isLogin", "wallet", "loading", "loadingBtn"],
  data() {
    return {
      stakeAmount: this.content.minStakePerUser - 10,
      stakeAmountInput: this.content.minStakePerUser,
      autoRenewal: true,
      termsConditions: false,
      collapseState: false,
      baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
      selectedTab: 0,
      myStakesColumn: [
        {
          title: this.$t("staking.plan_name"),
          align: "right",
          maxWidth: 75,
          render: (h, params) => {
            const date = `${this.content.duration} ${this.$t('exchange.day')} `
            return h("span", date);
          },
        },
        {
          title: this.$t("staking.remained_time"),
          align: "right",
          width: 100,
          render: (h, params) => {
            const date = `${params.row.remainingRegistrationTime} ${this.$t('exchange.day')} `
            return h("div", date);
          },
        },
        {
          title: this.$t("staking.auto_renewal"),
          align: "right",
          width: 100,
          render: (h, params) => {
            const autoRenewal = params.row.userApprovedRenewal ? this.$t('staking.has') : this.$t('staking.not_has')
            return h("span", autoRenewal);
          },
        },
        {
          title: this.$t("staking.plan_status_table"),
          align: "right",
          width: 100,
          render: (h, params) => {
            let status = "";
            switch (params.row.status) {
              case "CANCELED":
                status = this.$t("staking.status_cancel")
                break
              case "TERMINATED":
                status = this.$t("staking.status_terminated")
                break
              case "ACTIVE":
                status = this.$t("staking.status_active")
            }
            return h("span", status);
          },
        },

      ],
      navList: [
        { id: 0, label: this.$t("staking.stake_in_plan") },
        // {id: 1, label: this.$t("staking.my_stakes")},
      ],
    };
  },
  computed: {
    ...mapGetters({
      getMyStakeDetail: "staking/GET_MY_STAKE_DETAIL",
      getMyStakeData: "staking/GET_MY_STAKE_DATA"
    }),
  },
  methods: {
    removeThousand,
    ...mapActions({
      subscriptionsByPlanDetailId: "staking/GET_SUBSCRIPTIONS_BY_PLAN_DETAIL_ID",
    }),
    handleStakeAmount() {
      this.stakeAmount = Number(this.stakeAmountInput)
      this.stakeAmountInput = handleInput(this.stakeAmountInput, this.wallet.scale)
    },
    setAllBalance() {
      this.stakeAmountInput = this.numberWithCommas(this.wallet.balance, this.wallet.scale)
    },
    dateFormat: function (tick, option) {
      return this.$options.filters.dateFormat(tick, option);
    },
    collapseTrigger(e) {
      this.collapseState = !this.collapseState;
    },
    clearForm() {
      this.stakeAmount = this.content.minStakePerUser;
      this.stakeAmountInput = this.content.minStakePerUser;
      this.autoRenewal = true;
      this.termsConditions = false;
      this.collapseState = null;
    },
    closeModal() {
      this.clearForm();
      this.$emit("closeModal");
    },
    stakeButton() {
      let payload = {
        stakedAmount: Number(parseFloat(removeThousand(this.stakeAmountInput))),
        // isAutoRenewal: this.autoRenewal ? 1 : 0,
      };
      if (this.content.isRenewal) {
        payload.isAutoRenewal = this.autoRenewal ? 1 : 0
      } else {
        payload.isAutoRenewal = 0
      }
      this.$router.replace({ query: {} })
      !this.isLogin
        ? (window.location = `${this.baseUrl}/auth/login`)
        : this.$emit("subscribePlan", payload);
    },
    countDecimals(value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    },
    truncateToDecimals(num, dec = 2) {
      const calcDec = Math.pow(10, dec);
      return Math.trunc(num * calcDec) / calcDec;
    },
    logSlider(value) {
      this.stakeAmountInput = value;
    },
    updateSelectTab(id) {
      this.selectedTab = id;
      if (this.selectedTab === 0) {
        this.$store.commit("staking/SET_MY_STAKE_DETAIL", {})
        this.collapseState = false
      }
      if (this.selectedTab === 1) {
        this.subscriptionsByPlanDetailId({
          planDetailId: this.content.id,
        })
          .then((res) => {
            this.$store.commit("staking/SET_MY_STAKE_DATA", res.data.data)
          }).catch(err => this.$Message.error(err.message))
      }
    },
    rowClickHandler(currentRow) {
      this.$store.commit("staking/SET_MY_STAKE_DETAIL", currentRow)
    },
    cancelStake() {
      this.$emit("cancelModal", this.getMyStakeDetail.id)
    },
    handleAutoRenewalChange() {
      return new Promise((resolve) => {
        this.$emit("renewalModal", this.getMyStakeDetail.id, this.getMyStakeDetail.userApprovedRenewal);
      });
    },
    rowClassName(row, index) {
      if (row.id === this.getMyStakeDetail?.id) {
        return "activeClass";
      }
      return '';

    },
  },
  watch: {
    'content.id': function () {
      this.$emit("reloadDisclaimer")
      this.updateSelectTab(this.selectedTab)
    },
  },
  created() {
    if (this.isLogin) {
      this.navList.push({ id: 1, label: this.$t("staking.my_plans") });
    }

  },
};
</script>
<style lang="scss">
.stake-form {
  @media (max-width: 400px) {
    .responsive-reward-container {
      flex-direction: column;
      line-height: 32px;
    }
  }

  .left-side {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }

    .increase-balance {
      &:active {
        color: #f1ac1c;
      }

      &:hover {
        color: #f1ac1c;
      }
    }

    .slider-input-left-hint {
      left: 16px;
      top: 24px;
    }

    .slider-input-right-hint {
      right: 14px;
      top: 24px;
    }

    .insufficient-hint {
      right: 4px;
      top: 60px;
    }

    .terms-conditions-detail {
      background: transparent;
      border: none;
    }
  }

  .button-container {
    gap: 6%;
    bottom: 0;
    z-index: 9999;

    .confirm-btn {
      width: 60%;
    }

    .cancel-btn {
      width: 34%;
    }
  }

  .ivu-table .activeClass td {
    background: rgba(20, 30, 44, 1);
    color: #f1ac1c;
  }
}
</style>
